import React, { useContext, useEffect, useRef } from 'react'

import { gsap, Power2 } from 'gsap/dist/gsap'
import { SplitText } from 'gsap/dist/SplitText.js'
import { PageTransitionContext } from "../../context/PageTransitionContext";

// chars to exclude from first text animation
const charsToExclude = ['a', 'A', 'ä', 'á', 'e', 'E', 'ë', 'é', 'i', 'I', 'ï', 'í', 'o', 'O', 'ö', 'ó', 'u', 'U', 'ü', 'ú', 'y', 'Y', '.']

let title1aSplit = null
let title1bSplit = null

let animationRepeatDelay = 0
let animationStagger1 = 0.01
let animationStagger2 = 0.01
let animationDelay1 = 0
let animationDelay2 = 0.5
let animationDuration1 = 0.1
let animationDuration2 = 0.1

const AnimatedTitle = (props) => {

  // props
  const { title, page, startAnimation } = props;

  // refs
  const titleRef = useRef()
  const title1Ref = useRef()
  const title2Ref = useRef()

  // context
  const transitionContext = useContext(PageTransitionContext)

  let splitType = ''

  // useEffect for initial setup
  useEffect(() => {

    // some temp vars
    let tempWithoutVowels;
    let titleWithoutVowels = ''

    // split title on br
    const titleLines = title.split("<br />")

    // loop through each line, remove vowels and add back to a string
    for (let i = 0; i < titleLines.length; i++) {

      // split line on chars, take out vowels
      tempWithoutVowels = titleLines[i].split('').filter(value => !charsToExclude.includes(value))

      // add br back at end of each line (except last one)
      if (i < titleLines.length - 1){
        tempWithoutVowels = tempWithoutVowels.concat('<br />')
      }

      // combine chars of line and add to result string
      titleWithoutVowels = titleWithoutVowels.concat(tempWithoutVowels.join(''))
    }

    // set title text
    title1Ref.current.innerHTML = titleWithoutVowels
    title2Ref.current.innerHTML = title

    // create a splittext for the first title without vowels, shuffle the chars, initial settings
    title1aSplit = new SplitText(title1Ref.current, { type: 'chars' })
    gsap.utils.shuffle(title1aSplit.chars)
    gsap.set(title1aSplit.chars, { opacity: 0 })

    // create a splittext for the second title with vowels, shuffle the words or chars (based on resolution), initial settings
    splitType = (window.innerWidth < 640 && page !== 'home') ? 'words' : 'chars' // solution for weird breaking of words on mobile
    title1bSplit = new SplitText(title2Ref.current, { type: splitType })
    gsap.utils.shuffle((splitType === 'words') ? title1bSplit.words : title1bSplit.chars)
    gsap.set((splitType === 'words') ? title1bSplit.words : title1bSplit.chars, { opacity: 0 })

    switch (page){

      // beyond architecture
      case 'home':

        animationRepeatDelay = 0.2
        animationStagger1 = 0.015
        animationStagger2 = 0.015
        animationDelay1 = 0.2
        animationDelay2 = 0.8
        animationDuration1 = 0.1
        animationDuration2 = 0.1

        titleRef.current.classList.add('AnimatedTitle--home')

        break;

      case 'pagetransition':

        animationStagger1 = 0.008
        animationStagger2 = (splitType === 'words') ? 0.02 : 0.008
        animationDelay1 = 0.2
        animationDelay2 = 0.8
        animationDuration1 = 0.1
        animationDuration2 = (splitType === 'words') ? 0.5 : 0.1
        break;

    }

  }, []);

  useEffect(()=>{

    if (startAnimation){

      // animate first title
      gsap.to(title1aSplit.chars, {
        delay:animationDelay1,
        duration: animationDuration1,
        opacity: 1,
        stagger: animationStagger1,
        yoyo: true,
        repeat: 1,
        repeatDelay:animationRepeatDelay,
        ease: Power2.easeOut,
        onComplete: () => {
          if (title1Ref.current !== undefined && title1Ref.current !== null)
            title1Ref.current.classList.add('hidden')
        }
      })

      // animate second title
      gsap.to((splitType === 'words') ? title1bSplit.words : title1bSplit.chars, { delay: animationDelay2, duration: animationDuration2, opacity: 1, stagger: animationStagger2, ease: Power2.easeOut })

    }

    return () => {
      gsap.killTweensOf(title1aSplit.chars)
      gsap.killTweensOf(title1bSplit.chars)
    }

  }, [startAnimation])

  return (
    <div className={`AnimatedTitle ${transitionContext.pageType.length ? `is-${transitionContext.pageType}` : ''}`} ref={titleRef}>
      <h1 className="AnimatedTitle-title one" ref={title1Ref} />
      <h1 className="AnimatedTitle-title two" ref={title2Ref} />
    </div>
  )
}

export default AnimatedTitle
