// Manages the state for page transition animations
import { createContext, useState, useEffect } from 'react'

export const PageTransitionContext = createContext()

const PageTransitionProvider = (props) => {
  // states
  const [pageTransition, setPageTransition] = useState(false)
  const [bodyLock, setBodyLock] = useState(false)
  const [navigationIsOpen, setNavigationIsOpen] = useState(false)
  const [animatedTitle, setAnimatedTitle] = useState(false)
  const [style, setStyle] = useState('')
  const [showPage, setShowPage] = useState(false)
  const [pageType, setPageType] = useState('')

  const cleanUpTransition = ()=>{
    setPageTransition(false)
    setAnimatedTitle(false)
    setStyle('')
    setPageType('')
    setBodyLock(false)
  }

  /**
   * Control body lock from transition context
   */
  useEffect(() => {
    document.querySelector('body').style.overflow = bodyLock ? 'hidden' : ''
  }, [bodyLock])

  return (
    <PageTransitionContext.Provider value={{
      pageTransition,
      setPageTransition,
      bodyLock,
      setBodyLock,
      navigationIsOpen,
      setNavigationIsOpen,
      animatedTitle,
      setAnimatedTitle,
      style,
      setStyle,
      showPage,
      setShowPage,
      pageType,
      setPageType,
      cleanUpTransition
    }}>
      {props.children}
    </PageTransitionContext.Provider>
  )
}

export default PageTransitionProvider
