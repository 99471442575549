import { createContext, useState } from 'react'
import { useRouter } from 'next/router'

export const HomeContext = createContext()

const HomeContextProvider = (props) => {
  // router
  const router = useRouter()

  // states
  const [showIntroLogo, setShowIntroLogo] = useState((router.pathname === '/'))
  const [showTitle, setShowTitle] = useState(false)
  const [showBackgroundVideo, setShowBackgroundVideo] = useState(false)
  const [navigationIsVisible, setNavigationIsVisible] = useState((router.pathname !== '/'))
  const [logoReady, setLogoReady] = useState(false)

  return (
    <HomeContext.Provider value={{
      showIntroLogo,
      setShowIntroLogo,
      showTitle,
      setShowTitle,
      showBackgroundVideo,
      setShowBackgroundVideo,
      navigationIsVisible,
      setNavigationIsVisible,
      logoReady,
      setLogoReady
    }}>
      { props.children }
    </HomeContext.Provider>
  )
}

export default HomeContextProvider
